<template>
    <div class="hk-footer">
        <footer class="container-xxl footer">
            <div class="row">
                <div class="col-xl-8">
                    <p class="footer-text"><span class="copy-text">CentralCare © 2023 Tous droits réservés. V0.1</span> <a href="#"
                            class="" target="_blank">Politique de confidentialité</a><span class="footer-link-sep">|</span><a href="#"
                            class="" target="_blank">T&C</a><span class="footer-link-sep">|</span><a href="#" class=""
                            target="_blank">Statut système</a></p>
                </div>
                <div class="col-xl-4">
                    <a href="#" class="footer-extr-link link-default"><span class="feather-icon"><i
                                data-feather="external-link"></i></span><u>Envoyer des commentaires à notre mail pour nous aider à l'amélioration.</u></a>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {

}
</script>

<style></style>